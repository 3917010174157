const yup = require('yup')
const { requiredFields, labelify } = require('./helpers')

const shape = labelify({
  name: yup.string().default(''),
  profile: yup.mixed(),
  code: yup.string().length(5),
  createdBy: yup.mixed(),
  updatedBy: yup.mixed(),
})

const profileAccessCodeSchema = yup
  .object()
  .shape(shape)
  .shape(requiredFields(['name'])(shape))

module.exports = { profileAccessCodeSchema }
