import { props, state } from 'cerebral'
import { set, when } from 'cerebral/factories'
import * as actions from './actions'
import { saveEntities } from '../factories'
import { sequences as route } from '../route'
import { showFeedback } from '../feedback/factories'

export const find = [
  //
  actions.find,
  actions.setCalendarDefault,
  saveEntities(),
]

export const findForList = [
  //
  set(props`useRouteParams`, true),
  actions.find,
  actions.setCalendarDefault,
  actions.setList,
  saveEntities(),
]

export const get = [
  //
  actions.get,
  saveEntities(),
]

export const save = [
  //
  actions.save,
  actions.clearList,
  actions.setCalendarDefault,
  saveEntities(),
  showFeedback({ title: 'Calendar saved', type: 'success' }),
  route.toEventCalendar,
]

export const remove = [
  //
  actions.remove,
  findForList,
  actions.changeCalendar,
  showFeedback({ title: 'Calendar deleted', type: 'success' }),
  route.toEventCalendar,
]

export const changeCalendar = [
  //
  actions.changeCalendar,
  when(state`route.params.filter`, (filter) => filter === 'report'),
  {
    true: [
      //
      route.toEventReport,
    ],
    false: [
      //
      route.toEventCalendar,
    ],
  },
]
