import { DateTime } from 'luxon'
import { prop } from 'ramda'
import utils from '../../../shared/utils'

const { toDateTime } = utils

const appModules = {
  accountSetting: {
    moduleTitle: 'Admin Account',
    displayType: 'admin account',
    plural: 'admin accounts',
  },
  album: {
    moduleTitle: 'Albums',
    title: prop('name'),
  },
  capacityLocation: {
    moduleTitle: 'Locations',
    displayType: 'location',
    plural: 'locations',
    title: prop('name'),
  },
  contactInfo: {
    moduleTitle: 'Contact Info',
    displayType: 'contact info',
    plural: 'contact info',
  },
  appreciationAward: {
    moduleTitle: 'Awards',
    displayType: 'award',
    plural: 'awards',
  },
  event: {
    title: prop('title'),
  },
  eventAttendance: {
    moduleTitle: 'Attendance',
    displayType: 'event attendance',
    plural: 'event attendances',
  },
  eventRsvp: {
    moduleTitle: 'Event RSVP',
    displayType: 'event RSVP',
    plural: 'event RSVPs',
  },
  eventInstance: {
    moduleTitle: 'Attendance',
    displayType: 'event instance',
    plural: 'event instances',
  },
  invitations: {
    moduleTitle: 'People',
    plural: 'invitations',
  },
  kiosk: {
    moduleTitle: 'Playlists',
    displayType: 'playlist',
    plural: 'playlists',
  },
  kioskDevice: {
    moduleTitle: 'Displays',
    displayType: 'display',
    plural: 'displays',
  },
  libraryItem: {
    moduleTitle: 'Content Library',
    displayType: 'content',
    plural: 'content items',
  },
  // TODO: This customization should take into account the mobile label.
  lift: {
    moduleTitle: 'Lifts',
    plural: 'lifts',
  },
  locationDeviceEvent: {
    moduleTitle: 'Events',
    displayType: 'vehicle event',
    plural: 'vehicle events',
  },
  // TODO: This customization should take into account the mobile label.
  locationRoute: {
    moduleTitle: 'Routes',
    displayType: 'route',
    plural: 'routes',
  },
  locationRouteRequest: {
    moduleTitle: 'Shuttle Requests',
    displayType: 'shuttle request',
    plural: 'shuttle requests',
  },
  locationRouteDispatchRequest: {
    moduleTitle: 'Shuttle Dispatch Requests',
    displayType: 'shuttle dispatch request',
    plural: 'shuttle dispatch requests',
  },
  locationVehicle: {
    moduleTitle: 'Vehicles',
    displayType: 'vehicle',
    plural: 'vehicles',
  },
  locationVehicleMaintenanceSchedule: {
    moduleTitle: 'Maintenance Schedule',
    displayType: 'schedule',
    plural: 'schedules',
  },
  meal: {
    title: prop('title'),
  },
  mealLocation: {
    title: prop('title'),
    moduleTitle: 'Location',
    displayType: 'location',
    plural: 'locations',
  },
  media: {
    plural: 'media',
    title: prop('title'),
  },
  mobileWelcome: {
    moduleTitle: 'Welcome Messages',
    displayType: 'welcome message',
    plural: 'welcome messages',
  },
  mapPoint: {
    moduleTitle: 'Points of Interest',
    displayType: 'point of interest',
    plural: 'points of interest',
  },
  mapArea: {
    moduleTitle: 'Areas',
    displayType: 'area',
    plural: 'areas',
  },
  mapCategorie: {
    moduleTitle: 'Map categories',
    displayType: 'category',
    plural: 'categories',
  },
  publication: {
    moduleTitle: 'Publications',
  },
  pricing: {
    moduleTitle: 'Custom Pricing',
    displayType: 'custom pricing',
    plural: 'custom pricings',
  },
  profile: {
    moduleTitle: 'People',
    displayType: 'person',
    plural: 'people',
  },
  notification: {
    moduleTitle: 'Messages',
    displayType: 'message',
    plural: 'messages',
  },
  resource: {
    moduleTitle: 'Resources',
    title: prop('title'),
  },
  rideProduct: {
    moduleTitle: 'Ride Products',
    displayType: 'ride product',
    plural: 'ride products',
    title: prop('name'),
  },
  rideProductSubscription: {
    moduleTitle: 'Product Subscriptions',
    displayType: 'product subscription',
    plural: 'product subscriptions',
  },
  snowReport: {
    moduleTitle: 'Snow Reports',
    displayType: 'snow report',
    plural: 'snow reports',
    title: ({ reportDate } = {}) => (reportDate ? toDateTime(reportDate).toLocaleString(DateTime.DATE_MED) : 'Snow Report'),
  },
  surveyResponse: {
    plural: 'survey responses',
  },
  update: {
    moduleTitle: 'Notifications',
    displayType: 'notification',
    plural: 'notifications',
  },
}

export default appModules
