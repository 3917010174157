import { moduleState, props, state } from 'cerebral'
import { set, when } from 'cerebral/factories'
import { saveEntities } from '../factories'
import { showFeedback } from '../feedback/factories'
import { sequences as route } from '../route'
import * as actions from './actions'

export const find = [
  //
  actions.find,
  saveEntities(),
  actions.saveMobileUserMapping,
]

export const findForList = [
  //
  set(props`useRouteParams`, true),
  actions.adjustFilters,
  actions.find,
  actions.setList,
  saveEntities(),
  actions.saveMobileUserMapping,
]

export const loadById = [
  //
  actions.loadById,
  find,
]

export const get = [
  //
  actions.get,
  saveEntities(),
]

export const save = [
  //
  actions.save,
  actions.clearList,
  saveEntities(),
  showFeedback({ title: 'Profile saved', type: 'success' }),
  ({ props: { entities } }) => {
    const [result] = entities.profiles
    // Redirect back to appropriate user.
    return { params: { id: result?._id } }
  },
  route.toPerson,
]

export const saveWithoutFeedback = [
  //
  actions.save,
  saveEntities(),
]

export const updateUserTypes = [
  //
  actions.updateUserTypes,
  saveEntities(),
  showFeedback({ title: 'User roles updated', type: 'success' }),
]

export const updateRequestedUserTypes = [
  //
  actions.updateRequestedUserTypes,
  saveEntities(),
  showFeedback({ title: 'User role requests updated', type: 'success' }),
]

export const getProfilesRequestingRoles = [
  when(state`account.currentUser.canAccessCommandCenter`),
  {
    true: [
      //
      set(props`query.filter`, 'requesting'),
      find,
      set(moduleState`requestingRoleIds`, props`result.ids`),
    ],
    false: [],
  },
]

export const manageProfileRequestingRoles = [
  actions.manageProfileRequestingRoles,
  saveEntities(),
]

export const remove = [
  //
  actions.remove,
  showFeedback({ title: 'Profile removed', type: 'success' }),
  findForList,
]

export const reactivate = [
  //
  actions.reactivate,
  showFeedback({ title: 'Profile reactivated', type: 'success' }),
  findForList,
]

export const getTodaysBirthdays = [
  //
  actions.getTodaysBirthdays,
  saveEntities(),
]

export const acknowledgeBirthday = [
  //
  actions.acknowledgeBirthday,
  actions.getTodaysBirthdays,
  saveEntities(),
  when(state`route.path`, (path) => path.includes('update')),
  {
    true: [],
    false: [
      //
      showFeedback({ title: 'Birthday has been dismissed', type: 'success' }),
    ],
  },
]

export const setLevelOfCare = [
  //
  actions.setLevelOfCare,
]
