const yup = require('yup')
const { requiredFields, labelify } = require('./helpers')
const { uploadSchema } = require('./cloudinary-schema')

const defaultMealTypes = [
  { label: 'Breakfast', color: '#C1E2EC' },
  { label: 'Lunch', color: '#C9CDEE' },
  { label: 'Dinner', color: '#F2DEC5' },
]

const mealTypeSchema = yup.object().shape({
  _id: yup.string(),
  label: yup.string().required().ensure().default('Breakfast'),
  color: yup.string().ensure().default('#C1E2EC'),
  startAt: yup.date().default(() => new Date()),
  endAt: yup
    .date()
    .min(yup.ref('startAt'))
    .default(() => new Date()),
})

const shape = labelify({
  name: yup.string().default(''),
  details: yup.string().default(''),
  mealTypes: yup.array().ensure().of(mealTypeSchema).default(defaultMealTypes),
  image: uploadSchema,
  createdAt: yup.date(),
  updatedAt: yup.date(),
  createdBy: yup.mixed(),
  updatedBy: yup.mixed(),
})

const mealLocationSchemaPartial = yup.object().shape(shape)
const mealLocationSchema = mealLocationSchemaPartial.shape(requiredFields(['name'])(shape))

module.exports = { mealLocationSchema, mealTypeSchema, mealLocationSchemaPartial }
