import { state } from 'cerebral'
import * as sequences from './sequences'
import apiClient from '../../lib/util/api-client'
import schemaSettings from '../../../shared/schemas/update-schema'
import { createProviders } from '../providers'
import { redirectUnauthorized, createCrudState } from '../factories'
import { showFeedbackFromError } from '../feedback/factories'
import * as yup from 'yup'

const { updateSchema: schema } = schemaSettings

const service = apiClient.service('updates')
const displaysService = apiClient.service('updates/displays')

export default ({ name }) => ({
  state: {
    ...createCrudState({ entityType: 'updates', storePrefix: name })
  },
  sequences,
  providers: {
    ...createProviders({ name, service, schema }),
    ...createProviders({ name: 'displays', service: displaysService, schema: yup.object() }),
  },
  catch: [
    redirectUnauthorized(),
    [
      Error,
      showFeedbackFromError({ title: 'We encountered an error' }),
      ({ props: { error, errorPath = name }, store }) => {
        store.set(state`errors.${errorPath}`, error)
      },
    ],
  ],
})
