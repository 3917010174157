import { connect } from '@cerebral/react'
import { Box, Button, Link, Snackbar } from '@material-ui/core'
import { sequences, state } from 'cerebral'
import React from 'react'
import { capitalize } from 'underscore.string'
import format from '../../../shared/format'
import { expandEntities } from '../../modules/computed'
import { Check, Visibility } from '@material-ui/icons'
import { routePaths } from '../../modules/route'

interface RoutePaths {
  toPerson: Function
}

const { toPerson } = routePaths as RoutePaths

const CurrentSnackbars = connect(
  {
    birthdays: state`profiles.todaysBirthdays`,
    acknowledgeBirthday: sequences`profiles.acknowledgeBirthday`,
    setUpdateForBirthday: sequences`updates.setUpdateForBirthday`,
    requestingRoleIds: state`profiles.requestingRoleIds`, // This dependency is here to ensure the component redraws.
    updateUserTypes: sequences`profiles.updateUserTypes`,
  },

  ({ birthdays = [], acknowledgeBirthday, setUpdateForBirthday, updateUserTypes, get }) => {
    const profilesRequestingRole = expandEntities('profiles', state`profiles.requestingRoleIds`)(get)

    let index = 0
    return (
      <>
        {birthdays.map(({ name, _id: id }: any = {}) => {
          return (
            <Snackbar
              key={id}
              style={{ marginBottom: index++ * 60 }}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              open={true}
              message={`Today is ${name?.first || name?.display}'s birthday`}
              action={
                <Box style={{ display: 'flex', flexDirection: 'row' }}>
                  <Box mr={2} style={{ flex: 1 }}>
                    <Button color="primary" onClick={() => acknowledgeBirthday({ id })} size="small">
                      Dismiss
                    </Button>
                  </Box>
                  <Button color="primary" onClick={() => setUpdateForBirthday({ profileId: id })} size="small">
                    Announce on digital signage
                  </Button>
                </Box>
              }
            />
          )
        })}
        {profilesRequestingRole.map(({ name, userTypes = [], requestedUserTypes = [], _id: id }: any = {}) => {
          return requestedUserTypes.length === 0 ? null : (
            <Snackbar
              key={id}
              style={{ marginBottom: index++ * 60 }}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              open={true}
              message={`Role request from ${format.name(name)}`}
              action={
                <Box style={{ display: 'flex', flexDirection: 'row' }}>
                  {requestedUserTypes.map((role = '') => (
                    <Box key={role} mr={2}>
                      <Button color="primary"onClick={() => updateUserTypes({ id, userTypes: userTypes.concat(role) })} size="small" startIcon={<Check />}>
                        Grant {capitalize(role)} Role
                      </Button>
                    </Box>
                  ))}
                  <Button color="primary" href={toPerson({ id })} component={Link} size="small" startIcon={<Visibility />}>
                    Review
                  </Button>
                </Box>
              }
            />
          )
        })}
      </>
    )
  }
)

export default CurrentSnackbars
