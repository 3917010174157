import { props, state } from 'cerebral'
import { set, when } from 'cerebral/factories'
import * as actions from './actions'
import { saveEntities } from '../factories'
import { sequences as route } from '../route'
import { showFeedback } from '../feedback/factories'

export const find = [
  //
  actions.find,
  saveEntities(),
]

export const findForList = [
  //
  set(props`useRouteParams`, true),
  actions.find,
  actions.setList,
  saveEntities(),
]

export const get = [
  //
  actions.get,
  saveEntities(),
]

export const save = [
  //
  actions.save,
  actions.clearList,
  saveEntities(),
  showFeedback({ title: 'Location saved', type: 'success' }),
  route.toMealsCalendar,
]

export const remove = [
  //
  actions.remove,
  findForList,
  actions.changeMealLocation,
  showFeedback({ title: 'Location deleted', type: 'success' }),
  route.toMealsCalendar,
]

export const changeMealLocation = [
  //
  actions.changeMealLocation,
  when(state`route.params.filter`, (filter) => filter === 'report'),
  {
    true: [
      //
      route.toMealsReport,
    ],
    false: [
      //
      route.toMealsCalendar,
    ],
  },
]
