import { state } from 'cerebral'
import * as sequences from './sequences'
import apiClient from '../../lib/util/api-client'
import schemaSettings from '../../../shared/schemas/capacity-location-schema'
const { capacityLocationSchema: schema } = schemaSettings
import { createProviders } from '../providers'
import { redirectUnauthorized, createCrudState } from '../factories'
import { showFeedbackFromError } from '../feedback/factories'

const service = apiClient.service('capacity-locations')

export default ({ name, app }) => {
  app.on('initialized', () => {
    const updateEntities = app.getSequence('capacityLocations.updateEntities')
    service.on('patched', location => {
      location = schema.cast(location)
      updateEntities({ entities: { capacityLocations: [location] } })
    })
  })

  return {
    state: {
      ...createCrudState({ entityType: 'capacityLocations', storePrefix: name }),
      locationTypeIds: [],
      locationTypes: {},
    },
    sequences,
    providers: createProviders({ name, service, schema }),
    catch: [
      redirectUnauthorized(),
      [
        Error,
        showFeedbackFromError({ title: 'We encountered an error' }),
        ({ props: { error, errorPath = name }, store }) => {
          store.set(state`errors.${errorPath}`, error)
        },
      ],
    ],
  }
}
