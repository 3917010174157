import { state } from 'cerebral'
import * as sequences from './sequences'
import apiClient from '../../lib/util/api-client'
import schemaSettings from '../../../shared/schemas/location-vehicle-schema'
const { locationVehicleSchema: schema } = schemaSettings
import { createProviders } from '../providers'
import { redirectUnauthorized, createCrudState } from '../factories'
import { showFeedbackFromError } from '../feedback/factories'
import { locationVehiclesForMap, boundsForMap } from './computed'

const service = apiClient.service('location-vehicles')

export default ({ name, app }) => {
  app.on('initialized', () => {
    const updateEntities = app.getSequence('locationVehicles.updateEntities')
    service.on('patched', (location) => updateEntities({ entities: { locationVehicles: [location] } }))
  })

  return {
    state: {
      ...createCrudState({ entityType: 'locationVehicles', storePrefix: name }),
      shownOnMap: {},
      locationVehiclesForMap,
      lastLocation: {},
      boundsForMap,
    },
    sequences,
    providers: createProviders({ name, service, schema }),
    catch: [
      redirectUnauthorized(),
      [
        Error,
        showFeedbackFromError({ title: 'We encountered an error' }),
        ({ props: { error, errorPath = name }, store }) => {
          store.set(state`errors.${errorPath}`, error)
        },
      ],
    ],
  }
}
