import { connect } from '@cerebral/react'
import { Grid, Typography } from '@material-ui/core'
import { sequences, state } from 'cerebral'
import { Field } from 'formik'
import { TextField } from 'formik-material-ui'
import { pick } from 'ramda'
import React from 'react'
import schemaSettings from '../../../../shared/schemas/resort-schema'
import ValidatedForm from '../../blocks/ValidatedForm'
import FieldSpacing from '../../elements/FieldSpacing'
import LimitedTextField from '../../controls/LimitedTextField'

const { contactFormSchema } = schemaSettings

const Settings = connect(
  {
    organization: state`account.organization`,
    location: state`account.location`,
    saveSettings: sequences`account.saveContactSettings`,
  },

  (props) => ({
    ...props,
    saveSettings: (values) => props.saveSettings({ values: pick(['mobileIntro', 'socialMedia'], values) }),
  }),

  ({ organization = {}, location = {}, saveSettings }) => {
    const currentOrganization = location._id ? location : organization
    return (
      <>
        <ValidatedForm title="Contact Settings" initialValues={currentOrganization} schema={contactFormSchema} onSubmit={saveSettings}>
          {() => {
            return (
              <>
                <Grid md={12} xs={12} item>
                  <Typography variant="h4">Mobile Contact Screen Intro</Typography>
                  <FieldSpacing my={2}>
                    <Field multiline minRows="4" label="Intro" schema={contactFormSchema} name="mobileIntro.contact" component={LimitedTextField} />
                  </FieldSpacing>

                  <Typography variant="h4">Social Media URLs</Typography>
                  <FieldSpacing my={2}>
                    <Field label="Facebook" name="socialMedia.facebook" schema={contactFormSchema} component={TextField} />
                    <Field label="Instagram" name="socialMedia.instagram" schema={contactFormSchema} component={TextField} />
                    <Field label="TikTok" name="socialMedia.tikTok" schema={contactFormSchema} component={TextField} />
                    <Field label="Twitter" name="socialMedia.twitter" schema={contactFormSchema} component={TextField} />
                    <Field label="YouTube" name="socialMedia.youTube" schema={contactFormSchema} component={TextField} />
                    <Field label="Vimeo" name="socialMedia.vimeo" schema={contactFormSchema} component={TextField} />
                  </FieldSpacing>
                </Grid>
              </>
            )
          }}
        </ValidatedForm>
      </>
    )
  }
)

export default Settings
