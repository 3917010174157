import { Box } from '@material-ui/core'
import debounce from 'lodash.debounce'
import { MuiColorInput } from 'mui-color-input'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'

const DebouncedColorPicker = ({ label, field, form, defaultColor = '' }) => {
  const [debouncedValue, setDebouncedValue] = useState(field.value || defaultColor)

  const debouncedOnChange = debounce((value) => {
    form.setFieldValue(field.name, value)
  }, 200)

  useEffect(() => {
    debouncedOnChange(debouncedValue)
    return () => debouncedOnChange.cancel()
  }, [debouncedValue])

  return (
    <Box mt={2}>
      <MuiColorInput format="hex" label={label} value={debouncedValue} onChange={setDebouncedValue} InputProps={{ readOnly: false }} />
    </Box>
  )
}

DebouncedColorPicker.propTypes = {
  label: PropTypes.string,
  defaultColor: PropTypes.string,
  field: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
  }),
  form: PropTypes.shape({
    setFieldValue: PropTypes.func,
  }),
}

export default DebouncedColorPicker
