const { DateTime } = require('luxon')
const { nanoid, customAlphabet } = require('nanoid')
const { uppercase } = require('nanoid-dictionary')

const shortid = () => nanoid(9)

const uppercaseShortId = (length = 5) => customAlphabet(uppercase, length)()

function metersToMiles(distanceInMeters) {
  const conversionFactor = 0.6214
  return (distanceInMeters / 1000) * conversionFactor
}

const toDateTime = (d, tz, tzOptions = {}) => {
  return (d instanceof DateTime ? d : typeof d === 'string' ? DateTime.fromISO(d) : DateTime.fromJSDate(d)).setZone(tz, tzOptions)
}

const utils = { shortid, uppercaseShortId, metersToMiles, toDateTime }

module.exports = utils
