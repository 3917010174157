import { state } from 'cerebral'
import schemaSettings from '../../../shared/schemas/profile-schema'
const { profileSchema: schema } = schemaSettings
import apiClient from '../../lib/util/api-client'
import { createCrudState, redirectUnauthorized } from '../factories'
import { showFeedbackFromError } from '../feedback/factories'
import { createProviders } from '../providers'
import * as sequences from './sequences'

const service = apiClient.service('profiles')

export default ({ name, app }) => {
  app.on('initialized', () => {
    const getTodaysBirthdays = app.getSequence('profiles.getTodaysBirthdays')
    const getProfilesRequestingRoles = app.getSequence('profiles.getProfilesRequestingRoles')
    const manageProfileRequestingRoles = app.getSequence('profiles.manageProfileRequestingRoles')

    apiClient.on('login', () => {
      // Computed state is not available until next tick.
      setTimeout(getTodaysBirthdays, 0)
      setTimeout(getProfilesRequestingRoles, 0)
    })

    getTodaysBirthdays()
    getProfilesRequestingRoles()

    service.on('patched', (profile) => {
      manageProfileRequestingRoles({ profile })
    })
  })

  return {
    state: {
      ...createCrudState({ entityType: 'profiles', storePrefix: name }),
      mobileUserIdToProfileId: {},
      todaysBirthdays: [],
      requestingRoleIds: [],
      filter: {
        levelOfCare: '',
      },
    },
    sequences,
    providers: createProviders({ name, service, schema }),
    catch: [
      redirectUnauthorized(),
      [
        Error,
        showFeedbackFromError({ title: 'We encountered an error' }),
        ({ props: { error, errorPath = name }, store }) => {
          store.set(state`errors.${errorPath}`, error)
        },
      ],
    ],
  }
}
