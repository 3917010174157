import { state } from 'cerebral'
import * as sequences from './sequences'
import apiClient from '../../lib/util/api-client'
import schemaSettings from '../../../shared/schemas/package-schema'
const { packageSchema: schema } = schemaSettings
import { createProviders } from '../providers'
import { redirectUnauthorized, createCrudState } from '../factories'
import { showFeedbackFromError } from '../feedback/factories'
import * as yup from 'yup'

const service = apiClient.service('packages')
const mountainAreasService = apiClient.service('mountain-areas')

export default ({ name }) => ({
  state: {
    ...createCrudState({ entityType: 'prices', storePrefix: name }),
    mountainAreas: [],
  },
  sequences,
  providers: {
    ...createProviders({ name, service, schema }),
    ...createProviders({ name: 'mountainAreas', service: mountainAreasService, schema: yup.object() }),
  },
  catch: [
    redirectUnauthorized(),
    [
      Error,
      showFeedbackFromError({ title: 'We encountered an error' }),
      ({ props: { error, errorPath = name }, store }) => {
        store.set(state`errors.${errorPath}`, error)
      },
    ],
  ],
})
