import { connect } from '@cerebral/react'
import { Box, Button, Checkbox, FormControlLabel, Grid, Link, Typography } from '@material-ui/core'
import { Add, Delete, Refresh } from '@material-ui/icons'
import { sequences, state } from 'cerebral'
import { DateTime } from 'luxon'
import React, { useCallback, useEffect } from 'react'
import { dasherize, trim } from 'underscore.string'
import brands from '../../../../shared/brands'
import utils from '../../../../shared/utils'
import brand from '../../../lib/util/brand'
import QrCode from '../../blocks/QrCode'
import ActionHeader from '../../elements/ActionHeader'
import Notice from '../../elements/Notice'
import ConfirmDialog from '../../controls/ConfirmDialog'
import { useToggleState } from '../../../lib/util/hooks'

const { getDomain } = brands
const { shortid } = utils

const isQuiltt = brand.name === 'Quiltt'

const Organization = connect(
  {
    organization: state`account.organization`,
    mobileRoleRequestToken: state`account.organization.mobileRoleRequestToken`,
    location: state`account.location`,
    getRecipientCount: sequences`notifications.getRecipientCount`,
    recipientCount: state`notifications.recipientCount`,
    saveSettings: sequences`account.saveSettings`,
    timezone: state`account.currentUser.timezone`,
  },

  ({ organization, mobileRoleRequestToken, location, getRecipientCount, recipientCount, saveSettings, timezone }) => {
    const domain = getDomain(organization)
    const code = organization?.mobileAccessCode?.code
    const installPath = code ? `org/${organization.mobileAccessCode.code}` : 'appinstall'
    const installUrl = (domain && `https://${domain}/${installPath}`) || ''
    const roleRequestUrl = (domain && `https://${domain}/requestRole/${code || '__'}/${mobileRoleRequestToken}`) || ''
    const baseName = trim(dasherize(organization?.name || 'download'), ' -')
    const installDownload = (installUrl && `${baseName}.png`) || ''
    const roleRequestDownload = (roleRequestUrl && `${baseName}-roles.png`) || ''

    const dateMailArrivedAt = DateTime.fromISO(organization?.mailArrivedAt).setZone(timezone)
    const today = DateTime.now().setZone(timezone)

    useEffect(() => {
      if (organization && !location) {
        getRecipientCount({ audience: 'all' })
      }
    }, [organization, location])

    const setMailArrivedAt = useCallback(({ target: { checked } }) => saveSettings({ values: { mailArrivedAt: checked ? new Date() : null } }), [saveSettings])

    const [generateConfirmVisible, openGenerateConfirm, closeGenerateConfirm] = useToggleState()
    const generateRoleRequestToken = useCallback(() => saveSettings({ values: { mobileRoleRequestToken: shortid() } }), [saveSettings])
    const [clearConfirmVisible, openClearConfirm, closeClearConfirm] = useToggleState()
    const clearRoleRequestToken = useCallback(() => saveSettings({ values: { mobileRoleRequestToken: '' } }), [saveSettings])

    return (
      <Box>
        <ActionHeader title="Overview" section="Organization" />

        {!location && (
          <>
            <Grid container>
              <Grid item md={6} xs={12}>
                {isQuiltt && (
                  <Box mt={1} mb={2}>
                    <FormControlLabel
                      control={<Checkbox checked={Boolean(today.hasSame(dateMailArrivedAt, 'day'))} onChange={setMailArrivedAt} />}
                      label="Mail is here?"
                    />
                  </Box>
                )}

                <Notice>
                  <Typography variant="h2">{recipientCount}</Typography>
                  <Typography variant="body2">Total app users</Typography>
                </Notice>

                <Box my={6} />
              </Grid>
            </Grid>

            <Grid container>
              <Grid item md={6} xs={12}>
                <Typography variant="h3" gutterBottom>
                  Mobile App Install Link
                </Typography>
                <Link href={installUrl}>{installUrl}</Link>
                <Box style={{ maxWidth: 400 }}>
                  <QrCode code={installUrl} download={installDownload} width={1200} />
                </Box>
              </Grid>

              <Grid item md={6} xs={12}>
                <Typography variant="h3" gutterBottom>
                  Request Role Link
                </Typography>

                {mobileRoleRequestToken ? (
                  <>
                    <ConfirmDialog
                      open={generateConfirmVisible}
                      title="Generate new link?"
                      onConfirm={generateRoleRequestToken}
                      onClose={closeGenerateConfirm}
                    >
                      Regenerating the request link token will invalidate your existing link, and you will need to share the new link with users who need to request a role.
                    </ConfirmDialog>

                    <ConfirmDialog
                      open={clearConfirmVisible}
                      title="Remote link?"
                      onConfirm={clearRoleRequestToken}
                      onClose={closeClearConfirm}
                    >
                      Removing the request link token will invalidate your existing link, and no users will be able to request a role.
                    </ConfirmDialog>

                    <Box display="flex" flexDirection="row" mb={2}>
                      <Box mr={1}>
                        <Button startIcon={<Refresh />} variant="outlined" size="small" onClick={openGenerateConfirm}>
                          Regenerate role request link
                        </Button>
                      </Box>
                      <Button startIcon={<Delete />} variant="outlined" size="small" onClick={openClearConfirm}>
                        Remove role request link
                      </Button>
                    </Box>

                    <Link href={roleRequestUrl}>{roleRequestUrl}</Link>
                    <Box style={{ maxWidth: 400 }}>
                      <QrCode code={roleRequestUrl} download={roleRequestDownload} width={1200} />
                    </Box>
                  </>
                ) : (
                  <>
                    <Box mt={2}>
                      <Notice>
                        <p>If you create a role request link, you can provide your users with a link or QR code that will let them request the appropriate role for your organization.</p>
                        <p>New role requests will be available for you to review in the People section.</p>
                      </Notice>
                      <Button startIcon={<Add />} color="primary" size="small" onClick={generateRoleRequestToken}>
                        Create role request link
                      </Button>
                    </Box>
                  </>
                )}
              </Grid>
            </Grid>
          </>
        )}
      </Box>
    )
  }
)

export default Organization
