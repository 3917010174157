const yup = require('yup')
const { requiredFields, labelify } = require('./helpers')
const { uploadSchema } = require('./cloudinary-schema')
const mobileUserTypes = require('../mobile-user-types')

const linkedItemSchema = yup.object().shape({
  category: yup
    .string()
    .oneOf(['resource', 'reservation'])
    .when('$packages', (packages, schema) => {
      if (!packages) return schema
      if (packages['resources']) {
        return schema.default('resource')
      } else if (packages['reservations']) {
        return schema.default('reservation')
      }
    }),
  label: yup.string().default(''),
  resource: yup
    .mixed()
    .default('')
    .transform((curr, orig) => (orig === '' ? null : curr))
    .when(['category'], (category, schema) => {
      if (category !== 'resource') {
        return schema.transform(() => null)
      }
    }),
  reservationSchedule: yup
    .mixed()
    .default('')
    .transform((curr, orig) => (orig === '' ? null : curr))
    .when(['category'], (category, schema) => {
      if (category !== 'reservation') {
        return schema.transform(() => null)
      }
    }),
})

const shape = labelify({
  eventType: yup.string().oneOf(['Event', 'Menu']).default('Event'),
  featured: yup.boolean().default(false),
  title: yup.string().max(100).meta({ maxLength: 100 }).default(''),
  location: yup.string().max(100).meta({ maxLength: 100 }).default(''),
  locationShortCode: yup
    .string()
    .max(2)
    .meta({ maxLength: 2 })
    .transform((s) => s.toUpperCase())
    .default(''),
  locationLinkOnMobile: yup.boolean().default(false),
  label: yup.string().default(''),
  eventLabelRef: yup.mixed().transform((v) => v || null).nullable(),
  image: uploadSchema,
  libraryItem: yup.mixed(),
  startAt: yup.date().default(() => new Date()),
  endAt: yup
    .date()
    .min(yup.ref('startAt'))
    .default(() => new Date()),
  timezone: yup.string(),
  allDay: yup.boolean().default(false),
  multiDay: yup.boolean().default(false),
  intro: yup.string().max(200).meta({ maxLength: 200 }).default(''),
  details: yup.string().default(''),
  websiteDescription: yup.string().default(''),
  published: yup.boolean().nullable().default(false),
  restrictToUserType: yup.string().oneOf(mobileUserTypes.concat('')).ensure(),
  repeatType: yup.string().oneOf(['none', 'daily', 'weekly', 'monthly', 'yearly', 'custom']).default('none'),
  repeatRuleExceptDates: yup.array(yup.date()),
  calendars: yup.mixed().transform((value) => value || []),
  mealLocation: yup
    .mixed()
    .default('')
    .transform((curr, orig) => (orig === '' ? null : curr)),
  mealTypeRef: yup.mixed().transform((v) => v || null).nullable(),
  rrule: yup.object().shape({
    freq: yup.number().default(1),
    interval: yup.number().default(1),
    byweekday: yup.array().default([]),
    bysetpos: yup.number().default(0),
  }),
  rsvp: yup.object().shape({
    availability: yup.string().ensure().default('').label('RSVP availability'),
    capacity: yup.number().min(0).default(0).label('Maximum capacity'),
    maxPartySize: yup.number().min(0).max(yup.ref('capacity')).default(0).label('Maximum number in party'),
  }),
  linkedItems: yup.array().of(linkedItemSchema).default([]),
  editing: yup
    .object()
    .nullable()
    .shape({
      deleting: yup.boolean().nullable(),
      confirm: yup.boolean().nullable(),
      range: yup.string().oneOf(['future', 'this', 'all']).default('all'),
      instance: yup
        .string()
        .nullable()
        .when('$range', { is: 'future', then: (s) => s.required() }),
      targetInstance: yup.string().nullable(),
    }),
})

const required = requiredFields(['eventType', 'title', 'startAtDate', 'startAtTime', 'endAtTime'])
const mealRequired = requiredFields(['eventType', 'title', 'startAtDate'])

const eventSchemaPartial = yup.object().shape(shape)
const eventSchema = eventSchemaPartial.shape(required(shape))
const eventFormSchema = eventSchema.shape({
  calendars: yup
    .array()
    .of(yup.string().ensure().label('Calendar'))
    .transform((value) => (!value || !value?.length ? [''] : value))
    .default(['']),
  scheduledMedia: yup.mixed(),
  mealTypeRef: yup.string().ensure(),
  eventLabelRef: yup.string().ensure(),
})
const mealSchema = eventSchemaPartial.shape(mealRequired(shape))

module.exports = { eventSchema, eventSchemaPartial, mealSchema, eventFormSchema, linkedItemSchema }
