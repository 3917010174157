const yup = require('yup')
const mobileUserTypes = require('../mobile-user-types')
const levelsOfCare = require('../levels-of-care')
const { labelify } = require('./helpers')

const userTypes = yup.array().ensure().of(yup.string().oneOf(mobileUserTypes))

const shape = labelify({
  mobileUser: yup.mixed(),
  photo: yup.mixed(),
  name: yup.object().shape({
    display: yup.string().ensure().label('Name'),
    first: yup.string().ensure(),
    last: yup.string().ensure(),
  }),
  address: yup
    .object({
      street1: yup.string(),
      street2: yup.string(),
      city: yup.string(),
      state: yup.string(),
      zip: yup.string(),
      country: yup.string(),
    })
    .optional()
    .transform((v) => (v === null ? undefined : v)),
  addressCoordinates: yup
    .object()
    .shape({
      latitude: yup.number().nullable(),
      longitude: yup.number().nullable(),
    })
    .optional(),
  display: yup.object().shape(
    labelify({
      departmentName: yup.string().ensure(),
    })
  ),
  staffAppSectionsEnabled: yup.object().shape(
    labelify({
      alerts: yup.bool().default(false),
      lifts: yup.bool().default(false),
      trails: yup.bool().default(false),
      vehicles: yup.bool().default(false),
      locations: yup.bool().default(false),
    })
  ),
  hiddenCalendars: yup.array().of(yup.mixed()).default([]),
  isAllEventsTabHidden: yup.bool().default(false),
  trackAttendance: yup.bool().default(false),
  photosPermitted: yup.bool().default(true),
  phone: yup.string().ensure(),
  notes: yup.string().ensure(),
  interest: yup.string().ensure(),
  servedInMilitary: yup.bool().default(false),
  militaryBranch: yup
    .string()
    .ensure()
    .when('servedInMilitary', { is: true, then: (s) => s.required() }),
  includeInDirectory: yup.bool(),
  awardCount: yup.number().default(0),
  userTypes,
  requestedUserTypes: yup.array().nullable(),
  associatedWith: yup.string(),
  internalNotes: yup.string().max(500, 'Internal notes must be less than 500 characters.').nullable(),
  levelOfCare: yup.string().oneOf(levelsOfCare).nullable().default(''),
  birthdate: yup.date().max(new Date(), 'Please enter a valid date in the past.').nullable().default(null),
  birthdateAcknowledgedAt: yup.number().nullable(),
})

const deletedShape = {
  deleted: yup.boolean(),
  deletedAt: yup.date().nullable(),
  deletedBy: yup.mixed().nullable(),
}

const profileSchemaPartial = yup.object().shape(shape).shape(deletedShape)
const profileSchema = profileSchemaPartial

const profileFormSchema = yup
  .object()
  .shape(shape)
  .shape({
    address: yup.object({
      street1: yup.string().ensure(),
      street2: yup.string().ensure(),
      city: yup.string().ensure(),
      state: yup.string().ensure(),
      zip: yup.string().ensure(),
      country: yup.string().ensure(),
    }),
  })

const profileSchemaUserTypes = yup.object().shape({
  userTypes,
})

const profileSchemaDeleted = yup.object().shape(deletedShape)

const profileResponseSchema = profileSchema.shape({
  resort: yup.mixed(),
})

module.exports = { profileSchema, profileSchemaPartial, profileFormSchema, profileSchemaUserTypes, profileSchemaDeleted, profileResponseSchema }
