import React, { useState } from 'react'
import { connect } from '@cerebral/react'
import { Box, Button, FormControlLabel, Grid, Radio, Typography } from '@material-ui/core'
import { Field, Formik } from 'formik'
import { DatePicker, TimePicker } from 'formik-material-ui-pickers'
import { RadioGroup } from 'formik-material-ui'
import { propEq, find } from 'ramda'
import { sequences, state } from 'cerebral'
import ConfirmDialog from '../../controls/ConfirmDialog'
import FieldSpacing from '../../elements/FieldSpacing'
import { useToggleState } from '../../../lib/util/hooks'

const SeasonSwitch = connect(
  {
    resortSeasons: state`seasons.list.data`,
    saveSeasons: sequences`seasons.save`,
  },
  ({ resortSeasons = [], saveSeasons, displayToggler }) => {
    const [datePicker, setDatePicker] = useState('now')
    const [modalVisible, openModal, closeModal] = useToggleState(false)
    const switchSeason = ({ activeAfter }) => {
      const inactiveSeason = find(propEq('active', false))(resortSeasons)
      if (inactiveSeason) {
        saveSeasons({ values: { ...inactiveSeason, activeAfter, active: datePicker === 'now' } })
      }
    }

    return (
      <>
        {displayToggler ? (
          <Box mt={2} mb={4} display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h4">Resort Seasons</Typography>
            <Button onClick={openModal} color="primary" variant="outlined" title="Switch seasons">
              Switch seasons
            </Button>
          </Box>
        ) : null}

        <Formik initialValues={{ activeAfter: new Date() }} onSubmit={switchSeason}>
          {({ setFieldValue, handleSubmit }) => {
            return (
              <ConfirmDialog title="Switch seasons" fullWidth onClose={closeModal} onConfirm={handleSubmit} confirmText="OK" open={modalVisible}>
                <Typography variant="body1">Do you want to switch to the new season ?</Typography>
                <Grid container>
                  <FieldSpacing>
                    <RadioGroup>
                      <FormControlLabel
                        onChange={({ target: { value } }) => {
                          setDatePicker(value)
                          setFieldValue('activeAfter', new Date())
                        }}
                        value="now"
                        control={<Radio checked={datePicker === 'now'} />}
                        label="Switch now"
                      />

                      <Grid container direction="row" alignItems="center">
                        <Grid item md={3}>
                          <FormControlLabel
                            onChange={({ target: { value } }) => setDatePicker(value)}
                            value="date"
                            control={<Radio checked={datePicker === 'date'} />}
                            label="Switch on"
                          />
                        </Grid>

                        <Grid item md={9}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                              <Field
                                disabled={datePicker === 'now'}
                                size="small"
                                required
                                label="Start Date"
                                name="activeAfter"
                                format="DD"
                                variant="inline"
                                component={DatePicker}
                                minDate={new Date()}
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <Field
                                disabled={datePicker === 'now'}
                                size="small"
                                required
                                label="Start Time"
                                name="activeAfter"
                                variant="inline"
                                component={TimePicker}
                                minutesStep={5}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </RadioGroup>
                  </FieldSpacing>
                </Grid>
              </ConfirmDialog>
            )
          }}
        </Formik>
      </>
    )
  }
)

export default SeasonSwitch
