import React, { Suspense, lazy } from 'react'
import { connect } from '@cerebral/react'
import { state, sequences } from 'cerebral'
import { Typography } from '@material-ui/core'

import Home from './home/Home'
import Organization from './home/Organization'
import ContactInfoSettings from './home/ContactInfo'
import ContactSettings from './home/ContactSettings'
import { routeAccess } from '../../modules/route'
import { useEffect } from 'react'
import LoadingScreen from '../elements/LoadingScreen'
import GeneralSettings from './home/settings/General'
import MobileAppSettings from './home/settings/MobileApp'
import EventSettings from './home/settings/Event'

const NotFound = () => {
  return <Typography variant="h2">Not found</Typography>
}

const permitted = () => true

const components = {
  toRoot: Home,
  toLogin: lazy(() => import('./account/Login')),
  toAccountSetting: lazy(() => import('./account-settings/Form')),
  toAlbums: lazy(() => import('./albums/List')),
  toAlbum: lazy(() => import('./albums/Form')),
  toAlerts: lazy(() => import('./alerts/List')),
  toAlert: lazy(() => import('./alerts/Form')),
  toAwards: lazy(() => import('./appreciation-awards/List')),
  toAward: lazy(() => import('./appreciation-awards/Form')),
  toCapacityLocations: lazy(() => import('./capacity-locations/List')),
  toCapacityLocation: lazy(() => import('./capacity-locations/Form')),
  toCalendar: lazy(() => import('./calendars/Form')),
  toContactInfo: lazy(() => import('./contact-infos/Form')),
  toContactInfoSettings: ContactInfoSettings,
  toContactSettings: ContactSettings,
  toDisplays: lazy(() => import('./displays/List')),
  toDisplay: lazy(() => import('./displays/Form')),
  toEvents: lazy(() => import('./events/List')),
  toEvent: lazy(() => import('./events/Form')),
  toEventCalendar: lazy(() => import('./events/Calendar')),
  toEventReport: lazy(() => import('./events/Report')),
  toEventAttendances: lazy(() => import('./event-attendances/List')),
  toEventAttendance: lazy(() => import('./event-attendances/Form')),
  toEventRsvps: lazy(() => import('./event-rsvps/List')),
  toEventRsvp: lazy(() => import('./event-rsvps/Form')),
  toEventInstances: lazy(() => import('./event-instances/List')),
  toEventInstancesSummary: lazy(() => import('./event-instances/Summary')),
  toInvitations: lazy(() => import('./invitations/List')),
  toInvitation: lazy(() => import('./invitations/Form')),
  toInvitationsUpload: lazy(() => import('./invitations/Upload')),
  toLibraryItems: lazy(() => import('./library-items/List')),
  toLibraryItem: lazy(() => import('./library-items/View')),
  toLifts: lazy(() => import('./lifts/List')),
  toLift: lazy(() => import('./lifts/Form')),
  toLocationVehicles: lazy(() => import('./location-vehicles/List')),
  toLocationVehicleView: lazy(() => import('./location-vehicles/View')),
  toLocationVehicle: lazy(() => import('./location-vehicles/Form')),
  toLocationVehicleDrivers: lazy(() => import('./location-vehicle-drivers/List')),
  toLocationVehicleMaintenanceSchedule: lazy(() => import('./location-vehicle-maintenance-schedules/Form')),
  toLocationVehicleShifts: lazy(() => import('./location-vehicle-shifts/List')),
  toLocationRoutes: lazy(() => import('./location-routes/List')),
  toLocationRouteView: lazy(() => import('./location-routes/View')),
  toLocationRoute: lazy(() => import('./location-routes/Form')),
  toLocationRouteRequests: lazy(() => import('./location-route-requests/List')),
  toLocationRouteRequestView: lazy(() => import('./location-route-requests/View')),
  toLocationRouteRequest: lazy(() => import('./location-route-requests/Form')),
  toLocationRouteDispatchRequests: lazy(() => import('./location-route-dispatch-requests/List')),
  toMapPoints: lazy(() => import('./map-points/List')),
  toMapPoint: lazy(() => import('./map-points/Form')),
  toMapAreas: lazy(() => import('./map-areas/List')),
  toMapArea: lazy(() => import('./map-areas/Form')),
  toMapCategories: lazy(() => import('./map-categories/List')),
  toMapCategorie: lazy(() => import('./map-categories/Form')),
  toMedias: lazy(() => import('./medias/List')),
  toMedia: lazy(() => import('./medias/Form')),
  toMealsCalendar: lazy(() => import('./events/Calendar')),
  toMealsReport: lazy(() => import('./events/Report')),
  toMealsUpload: lazy(() => import('./events/MealsUpload')),
  toMeals: lazy(() => import('./events/List')),
  toMeal: lazy(() => import('./events/Form')),
  toMealLocation: lazy(() => import('./meal-locations/Form')),
  toMobileWelcomes: lazy(() => import('./mobile-welcomes/List')),
  toMobileWelcome: lazy(() => import('./mobile-welcomes/Form')),
  toNotifications: lazy(() => import('./notifications/List')),
  toNotificationView: lazy(() => import('./notifications/View')),
  toNotification: lazy(() => import('./notifications/Form')),
  toPublications: lazy(() => import('./publications/List')),
  toPublication: lazy(() => import('./publications/Form')),
  toOrganization: Organization,
  toSettings: GeneralSettings,
  toMobileSettings: MobileAppSettings,
  toEventSettings: EventSettings,
  toPeople: lazy(() => import('./people/List')),
  toPerson: lazy(() => import('./people/Form')),
  toPlaylists: lazy(() => import('./kiosks/List')),
  toPlaylist: lazy(() => import('./kiosks/Form')),
  toProfile: lazy(() => import('./profiles/Form')),
  toPrices: lazy(() => import('./prices/List')),
  toPrice: lazy(() => import('./prices/Form')),
  toPricings: lazy(() => import('./pricings/List')),
  toPricing: lazy(() => import('./pricings/Form')),
  toReservations: lazy(() => import('./reservations/List')),
  toReservation: lazy(() => import('./reservations/Form')),
  toResources: lazy(() => import('./resources/List')),
  toResource: lazy(() => import('./resources/Form')),
  toRideProducts: lazy(() => import('./ride-products/List')),
  toRideProduct: lazy(() => import('./ride-products/Form')),
  toRideProductSubscriptions: lazy(() => import('./ride-product-subscriptions/List')),
  toSnowStats: lazy(() => import('./snow-stats/Form')),
  toSnowReports: lazy(() => import('./snow-reports/List')),
  toSnowReport: lazy(() => import('./snow-reports/Form')),
  toSurveys: lazy(() => import('./surveys/List')),
  toSurvey: lazy(() => import('./surveys/Form')),
  toSurveyResponses: lazy(() => import('./surveyResponses/List')),
  toTrails: lazy(() => import('./trails/List')),
  toTrail: lazy(() => import('./trails/Form')),
  toUpdates: lazy(() => import('./updates/List')),
  toUpdate: lazy(() => import('./updates/Form')),
}

const Router = connect(
  {
    currentRoute: state`route.key`,
    packages: state`account.packages`,
    checked: state`account.checked`,
    toRoot: sequences`route.toRoot`,
  },
  ({ currentRoute, packages, checked, toRoot }) => {
    const Component = components[currentRoute] || NotFound
    const accessGranted = (routeAccess[currentRoute] || permitted)(packages)

    useEffect(() => {
      if (checked && !accessGranted) {
        toRoot()
      }
    }, [checked, accessGranted])

    return checked ? <Suspense fallback={<LoadingScreen />}>{accessGranted && <Component />}</Suspense> : <LoadingScreen />
  }
)

export default Router
