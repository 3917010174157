import { moduleState, state } from 'cerebral'
import { DateTime } from 'luxon'
import { isNull, omitBy } from 'ramda-adjunct'
import { find as rFind, propEq, isNil } from 'ramda'
import schemaSettings from '../../../shared/schemas/event-instance-schema'
const { eventInstanceResponseSchema } = schemaSettings
import apiClient from '../../lib/util/api-client'
import { crudActions } from '../factories'

const dashboardService = apiClient.service('event-instances/dashboard')
const reportService = apiClient.service('event-instances/planning-report')
const omitNull = omitBy(isNull)

export const { find, setList, clearList, get, save, remove } = crudActions({ name: 'eventInstances', schemas: { find: eventInstanceResponseSchema } })

export const useTimeRange = ({ props, get }) => {
  if (!props?.query?.date) {
    const timezone = get(state`account.currentUser.timezone`)
    const getDate = (date) => date && DateTime.fromISO(date, { zone: timezone }).setZone('utc', { keepLocalTime: true }).startOf('day')
    const { query = {} } = props
    const { startDate, endDate } = get(moduleState`dashboard`) || {}
    query.date = (startDate || endDate) ? omitNull({ $gte: getDate(startDate)?.startOf('day'), $lte: getDate(endDate)?.endOf('day') }) : undefined
    delete query._id
    return { query }
  }
}

export const dashboardSetTimeRange = ({ props: { selectedFilter, days, offset, profile = null }, store, get }) => {
  const timezone = get(state`account.currentUser.timezone`)
  store.set(moduleState`dashboard.selectedFilter`, selectedFilter)
  const now = DateTime.local().setZone(timezone).plus({ days: offset || 0 }).setZone('utc', { keepLocalTime: true }).startOf('day')

  const hasValidDays = !isNil(days)
  const startDate = hasValidDays ? now.minus({ days }) : null
  const endDate = hasValidDays ? now : null

  store.set(moduleState`dashboard.endDate`, endDate && endDate.toISODate())
  store.set(moduleState`dashboard.startDate`, startDate && startDate.toISODate())
  store.set(moduleState`dashboard.profile`, profile)
  // FIXME: This is a messy approach. It stashes a query onto the route to cause the list to redraw.
  // It also effectively overrides/duplicates the useTimeRange logic above.
  store.set(state`route.params.date`, (startDate || endDate) ? omitNull({ $gte: startDate?.startOf('day'), $lte: endDate?.endOf('day') }) : { $ne: null })
}

export const dashboardSetCalendar = ({ props: { calendar }, store }) => {
  store.set(moduleState`dashboard.selectedCalendar`, calendar)
  // FIXME: This is a messy approach. It stashes a query onto the route to cause the list to redraw.
  if (calendar){
    store.set(state`route.params.calendars`, calendar)
  }else{
    store.unset(state`route.params.calendars`)
  }
}

export const dashboardDataReset = ({ props: { profile = null }, store }) => {
  store.set(moduleState`dashboard.data`, null)
  store.set(moduleState`dashboard.profile`, profile)
}

export const dashboardRefresh = async ({ store, get }) => {
  const { startDate, endDate, profile, selectedCalendar } = omitNull(get(moduleState`dashboard`) || {})
  const data = await dashboardService.find({ query: { startDate, endDate, profile, calendar: selectedCalendar } }) || null
  store.set(moduleState`dashboard.data`, data)
}

export const getReport = async ({ props }) => {
  const { startDate, endDate, eventType, calendars, mealLocation, skipCache } = props.query
  const report = await reportService.find({ query: { startDate, endDate, eventType, calendars, mealLocation, skipCache } })
  return { report }
}

export const mapCalendarFields = ({ props: { entities }, get }) => {
  const timezone = get(state`account.currentUser.timezone`)
  const labels = get(state`account.eventLabels`)
  const eventLocationCode = get(state`account.eventLocationCode`)
  let { eventInstances } = entities
  const typeCache = {}
  const locations = {}

  eventInstances = eventInstances.map((eventInstance) => {
    let {
      startAt,
      endAt,
      eventType,
      event: { title, location = '', locationShortCode, allDay, featured, mealLocation, eventLabelRef, mealTypeRef } = {},
    } = eventInstance
    const labelRef = eventType === 'Menu' && mealLocation ? mealTypeRef : eventLabelRef

    startAt = DateTime.fromJSDate(startAt).setZone(timezone)
    endAt = DateTime.fromJSDate(endAt).setZone(timezone)

    let eventTitle = title

    if (eventLocationCode && locationShortCode) {
      if (location) {
        locations[locationShortCode] = location
      }
      eventTitle = `${title} [${locationShortCode}]`
    }

    const start = allDay ? startAt.toISODate() : startAt.toISO()
    const end = allDay ? endAt.plus({ day: 1 }).toISODate() : endAt.toISO()

    eventInstance = { ...eventInstance, title: eventTitle, startISO: start, start, end, extendedProps: { shortCode: locationShortCode, featured } }

    // Set event color based on the resort settings.
    const labelKey = `${eventType}:${labelRef}`
    const typeLabels = labels[eventType]
    let found = typeCache[labelKey]

    if (!found && typeLabels) {
      found = rFind(propEq('_id', labelRef), typeLabels)
    }

    if (found) {
      eventInstance.extendedProps = {
        color: found.color,
        labelColor: found.color,
        labelTextColor: found.textColor,
        shortCode: found.shortCode,
        featured
      }
    }

    return eventInstance
  })

  return { eventInstances, locations }
}
