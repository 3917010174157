import { connect } from '@cerebral/react'
import { Box, Divider, Grid, Typography } from '@material-ui/core'
import { state, sequences } from 'cerebral'
import { Field } from 'formik'
import { CheckboxWithLabel, TextField } from 'formik-material-ui'
import { isNumber } from 'ramda-adjunct'
import React from 'react'
import Notice from '../../../elements/Notice'
import DropUpload from '../../../controls/DropUpload'
import { omitStateFlag } from '../../../../lib/util/cerebral'
import brand from '../../../../lib/util/brand'
import LimitedTextField from '../../../controls/LimitedTextField'
import schemaSettings from '../../../../../shared/schemas/resort-schema'
import ValidatedForm from '../../../blocks/ValidatedForm'
import { pick } from 'ramda'

const { settingsFormSchema } = schemaSettings

const isQuiltt = brand.name === 'Quiltt'

const GeneralSettings = connect(
  {
    organization: state`account.organization`,
    saveSettings: sequences`account.saveSettings`,
  },
  (props) => ({
    ...props,
    saveSettings: (values) => props.saveSettings({ values: pick(['address', 'logo', 'birthdayUpdates'], values) }),
  }),
  ({ organization, saveSettings }) => {
    return organization?._id ? (
      <ValidatedForm title="General Settings" section="Organization" initialValues={organization} schema={settingsFormSchema} onSubmit={saveSettings}>
        {({ setFieldValue, errors, values: { logo, birthdayUpdates: { automaticallyCreate } = {} } }) => {
          return (
            <Box flexDirection="row">
              {(!isNumber(organization.latitude) || !isNumber(organization.longitude) || !organization.timezone) && (
                <Notice variant="info">Please enter an address for {organization.name} to finish setting up your account.</Notice>
              )}
              <Grid spacing={4} container>
                <Grid md={6} xs={12} item>
                  <Typography variant="h4">Address</Typography>
                  <Field label="Street" name="address.street1" component={TextField} />
                  <Field name="address.street2" component={TextField} />
                  <Field label="City" name="address.city" component={TextField} />
                  <Field label="State" name="address.state" component={TextField} />
                  <Field label="Zip" name="address.zip" component={TextField} />
                </Grid>

                <Grid md={6} xs={12} item>
                  <Typography variant="h4" gutterBottom>
                    Logo
                  </Typography>
                  <DropUpload optional ratio={9 / 16} image={omitStateFlag(logo)} onChange={(image) => setFieldValue('logo', image)} error={errors.logo} />
                </Grid>
              </Grid>

              {isQuiltt && (
                <>
                  <Box mt={3} mb={3}>
                    <Divider />
                  </Box>
                  <Box mb={2}>
                    <Typography variant="h4">Notifications</Typography>
                  </Box>
                  <Field
                    Label={{ label: 'Automatically create birthday announcements?' }}
                    name="birthdayUpdates.automaticallyCreate"
                    type="checkbox"
                    component={CheckboxWithLabel}
                  />
                  {automaticallyCreate && (
                    <>
                      <Notice>
                        On a resident&apos;s birthday, a notification for signage will be automatically created. The following placeholders will be replaced
                        with resident information: {`{{first_name}}, {{last_name}}, {{first_initial}}, {{last_initial}}`}
                      </Notice>
                      <Field
                        required
                        label="Primary Update Template"
                        name="birthdayUpdates.primaryTemplate"
                        schema={settingsFormSchema}
                        component={LimitedTextField}
                      />
                      <Field
                        multiline
                        minRows="4"
                        label="Details Template (optional)"
                        name="birthdayUpdates.detailsTemplate"
                        schema={settingsFormSchema}
                        component={LimitedTextField}
                      />
                    </>
                  )}
                </>
              )}
            </Box>
          )
        }}
      </ValidatedForm>
    ) : null
  }
)

export default GeneralSettings
