import { moduleState } from 'cerebral'
import { crudActions } from '../factories'
import schemaSettings from '../../../shared/schemas/location-vehicle-schema'
const { locationVehicleResponseSchema: responseSchema } = schemaSettings

export const { find, setList, clearList, get, save, remove } = crudActions({ name: 'locationVehicles', schemas: { find: responseSchema } })

export const showOnMap = ({ props, store }) => {
  const { show, id } = props
  store.set(moduleState`shownOnMap.${id}`, show)
}
