import { crudActions } from '../factories'
import { moduleState } from 'cerebral'
import schemaSettings from '../../../shared/schemas/package-schema'
const { packageResponseSchema } = schemaSettings

export const { find, setList, clearList, get, save, remove } = crudActions({ name: 'prices', schemas: { find: packageResponseSchema } })

export const getMountainAreas = async ({ mountainAreasService, store }) => {
  const { data } = await mountainAreasService.find()
  store.set(moduleState`mountainAreas`, data)
}
