import React, { useEffect } from 'react'
import { sequences, state } from 'cerebral'
import { connect } from '@cerebral/react'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import Layout from './layout/Layout'
import { Helmet } from 'react-helmet'
import { create } from 'jss'
import { jssPreset, StylesProvider, ThemeProvider } from '@material-ui/core'
import Router from './views/Router'
import { createMUITheme } from '../theme'
import DateUtils, { setCurrentTimezone } from '../lib/util/date-utils'

const jss = create({ plugins: jssPreset().plugins })

const defaultTitle = 'Manage - Alpine Media Technology'
const titleTemplate = `%s - ${defaultTitle}`

const App = connect(
  {
    checkAccount: sequences`account.reauthenticate`,
    currentUser: state`account.currentUser`,
    timezone: state`account.currentUser.timezone`,
    getInfo: sequences`info.getInfo`,
    requestingCount: state`locationRouteRequests.requestingCount`,
  },
  ({ checkAccount, currentUser, timezone, getInfo, requestingCount }) => {
    useEffect(() => {
      checkAccount()
    }, [])

    useEffect(() => {
      if (currentUser?.canAccessCommandCenter) {
        getInfo()
      }
    }, [currentUser?.canAccessCommandCenter])

    if (timezone) {
      setCurrentTimezone(timezone)
    }

    const countPrefix = requestingCount ? `(${requestingCount}) ` : ''

    return (
      <ThemeProvider theme={createMUITheme({ responsiveFontSizes: true })}>
        <MuiPickersUtilsProvider utils={DateUtils}>
          <StylesProvider jss={jss}>
            <Helmet titleTemplate={`${countPrefix}${titleTemplate}`} defaultTitle={`${countPrefix}${defaultTitle}`} defer={false} />
            <Layout>
              <Router />
            </Layout>
          </StylesProvider>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    )
  }
)

export default App
