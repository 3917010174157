import PropTypes from 'prop-types'
import React from 'react'
import { Add } from '@material-ui/icons'
import { Box, Button } from '@material-ui/core'

export const AddButton = ({ title, ...props }) => {
  return (
    <Box mt={1}>
      <Button startIcon={<Add />} {...props}>
        {title}
      </Button>
    </Box>
  )
}

AddButton.propTypes = {
  title: PropTypes.string.isRequired,
}
