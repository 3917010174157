import { state } from 'cerebral'
import { pluck, prop, reduce, toPairs, values } from 'ramda'
import { crudActions, tagActions } from '../factories'
import daysOfWeek from '../../../shared/schemas/fields/days-of-week'
const { rruleDaysOfWeekKeys, rruleDaysOfWeekIndexes } = daysOfWeek
import schemaSettings from '../../../shared/schemas/trail-schema'
const { trailScheduleSchema } = schemaSettings

export const { find, setList, clearList, get, save, remove } = crudActions({ name: 'trails' })

export const clearAllOverrides = async ({ trailsService, get }) => {
  const items = get(state`trails.list.data`)
  if (items) {
    const ids = pluck('_id', items.map(get).filter(prop('hasOverride')))
    const trails = await Promise.all(
      ids.map((id) => {
        return trailsService.patch(id, { override: {} })
      })
    )
    return { entities: { trails } }
  }
}

export const expandHoursToSchedule = ({ props }) => {
  let {
    entities: { trails = [], ...entities },
  } = props

  trails = trails.map((trail) => {
    const { hours } = trail

    trail.schedules = values(
      reduce(
        (result, [day, { open, close }]) => {
          if (!open || !close) {
            return result
          }

          const key = `${open}--${close}`
          const dayIndex = rruleDaysOfWeekIndexes[day]
          if (result[key]) {
            result[key].rrule.byweekday.push(dayIndex)
          } else {
            result[key] = trailScheduleSchema.cast({ start: open, end: close, rrule: { byweekday: [dayIndex] } })
          }
          return result
        },
        {},
        toPairs(hours)
      )
    )

    return trail
  })

  return { entities: { ...entities, trails } }
}

export const setHoursFromSchedule = ({ props }) => {
  const { values } = props
  const { schedules = [] } = values
  const hours = {}

  schedules.forEach(({ start: open, end: close, rrule: { byweekday } }) => {
    byweekday.forEach((dayIndex) => {
      hours[rruleDaysOfWeekKeys[dayIndex]] = { open, close }
    })
  })

  return { values: { ...values, hours } }
}

export const { collectTags } = tagActions()
